import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "about-page",
  "title": "Latino Caucus"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`What’s New?`}</h3>
    <p>{`\\
The Post Guild’s Latino Caucus is partnering with the `}<a parentName="p" {...{
        "href": "https://latguild.com/news/2020/7/21/latino-caucus-letter"
      }}>{`Los Angeles Times’ Latino Caucus`}</a>{` to host a virtual Open House for students interested in pursuing careers in journalism. The event will be on Zoom, on Saturday, Nov. 13, from 10:30 a.m. to 2 p.m. PT; 1:30 to 5 p.m. ET. It’s free and open to all students. `}<a parentName="p" {...{
        "href": "https://docs.google.com/forms/d/126KSAYYVkfpcRrxE6ZLuQNeWGmqil59Gx2McH64gGfU/edit?usp=drive_web"
      }}>{`Register to attend here`}</a>{`!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "58.282208588957054%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAECA//EABcBAAMBAAAAAAAAAAAAAAAAAAECAwX/2gAMAwEAAhADEAAAAbrFQ0WSF//EABkQAQACAwAAAAAAAAAAAAAAAAEAAhAREv/aAAgBAQABBQKtRC3EXbn/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGH/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAx/9oACAECAQE/AUHZ/8QAGRAAAgMBAAAAAAAAAAAAAAAAABEBICEx/9oACAEBAAY/AtkXR0//xAAaEAEAAgMBAAAAAAAAAAAAAAABABEhMUFh/9oACAEBAAE/IbJTEGg5TGZbl+EGuRzP/9oADAMBAAIAAwAAABDEz//EABcRAAMBAAAAAAAAAAAAAAAAAAABMRH/2gAIAQMBAT8QerBQ/8QAFhEBAQEAAAAAAAAAAAAAAAAAAREA/9oACAECAQE/EDYMEIb/xAAbEAEAAgMBAQAAAAAAAAAAAAABABEhMVFxof/aAAgBAQABPxAOBrVAdmH85WibCW5VK+c4D7Fa6Dyf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "The Post Guild’s Latino Caucus is partnering with the [Los Angeles Times’ Latino Caucus](https://latguild.com/news/2020/7/21/latino-caucus-letter) to host a virtual Open House for students interested in pursuing careers in journalism. The event will be on Zoom, on Saturday, Nov. 13, from 10:30 a.m. to 2 p.m. PT; 1:30 to 5 p.m. ET. It’s free and open to all students.",
          "title": "The Post Guild’s Latino Caucus is partnering with the [Los Angeles Times’ Latino Caucus](https://latguild.com/news/2020/7/21/latino-caucus-letter) to host a virtual Open House for students interested in pursuing careers in journalism. The event will be on Zoom, on Saturday, Nov. 13, from 10:30 a.m. to 2 p.m. PT; 1:30 to 5 p.m. ET. It’s free and open to all students.",
          "src": "/static/4988a5f9011399eb60b9824a2e97b885/6aca1/email-flyer.jpg",
          "srcSet": ["/static/4988a5f9011399eb60b9824a2e97b885/d2f63/email-flyer.jpg 163w", "/static/4988a5f9011399eb60b9824a2e97b885/c989d/email-flyer.jpg 325w", "/static/4988a5f9011399eb60b9824a2e97b885/6aca1/email-flyer.jpg 650w", "/static/4988a5f9011399eb60b9824a2e97b885/7c09c/email-flyer.jpg 975w", "/static/4988a5f9011399eb60b9824a2e97b885/01ab0/email-flyer.jpg 1300w", "/static/4988a5f9011399eb60b9824a2e97b885/8b619/email-flyer.jpg 5000w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3>{`About the Post Guild’s Latino Caucus`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "62.576687116564415%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMBBP/EABYBAQEBAAAAAAAAAAAAAAAAAAACBP/aAAwDAQACEAMQAAABjanNGgwf/8QAGhABAAIDAQAAAAAAAAAAAAAAAQISAAMxMv/aAAgBAQABBQKqyFjkt1WlR9Pf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAITAf/aAAgBAwEBPwGTaQc//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bp//EABwQAQACAQUAAAAAAAAAAAAAABEAASECEDFBYf/aAAgBAQAGPwJZbecQ0nrU56I7f//EAB0QAAICAQUAAAAAAAAAAAAAAAERADEhQVGBscH/2gAIAQEAAT8hoV3xEUBVhpDuOwilILF0yJr8gNt5/9oADAMBAAIAAwAAABCwP//EABcRAAMBAAAAAAAAAAAAAAAAAAABIXH/2gAIAQMBAT8QSsMj/8QAFhEBAQEAAAAAAAAAAAAAAAAAAQAR/9oACAECAQE/EA7aX//EAB4QAQABBAIDAAAAAAAAAAAAAAERACExUUFhcZGx/9oACAEBAAE/EAOKxzNxtvqnHsAXyIT2T5aKzUTcd4dUulUxNmpygSSGNPlTyUbmOa//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "latino caucus grid",
          "title": "latino caucus grid",
          "src": "/static/9f2535574d711f574a5f65cd79837e56/6aca1/latino-caucus-grid.jpg",
          "srcSet": ["/static/9f2535574d711f574a5f65cd79837e56/d2f63/latino-caucus-grid.jpg 163w", "/static/9f2535574d711f574a5f65cd79837e56/c989d/latino-caucus-grid.jpg 325w", "/static/9f2535574d711f574a5f65cd79837e56/6aca1/latino-caucus-grid.jpg 650w", "/static/9f2535574d711f574a5f65cd79837e56/7c09c/latino-caucus-grid.jpg 975w", "/static/9f2535574d711f574a5f65cd79837e56/01ab0/latino-caucus-grid.jpg 1300w", "/static/9f2535574d711f574a5f65cd79837e56/ad722/latino-caucus-grid.jpg 5996w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <p>{`The Washington Post Guild’s Latino Caucus was established to support, champion and provide a welcoming space for Latino staffers to collaborate and push for greater inclusion. The Latino Caucus will advocate for accurate and responsible representation of the Latino community in both the Post’s coverage and workplace culture and practices. While 17.1 percent of the D.C. region’s population and 18.5 percent of the country’s population identify as Hispanic or Latino, only 5.8 percent of staffers at The Washington Post identify as such. We are here to change that.`}</p>
    <p>{`El Latino Caucus de el Washington Post Guild se creó para brindar apoyo para el personal latino de este periódico. Nuestra meta es avanzar y promover el trabajo y las carreras de nuestros compañeros Latinos. Este grupo también fue creado para abogar por una mejor representación de la población latina e hispana de Washington D.C. y los Estados Unidos en nuestra sala de redacción. Mientras que el 17,1 por ciento de la población de Washington y el 18,5 por ciento de la población de los Estados Unidos se identifica como hispanos o latinos, solo el 5,8 por ciento de los empleados de el Washington Post se identifican como tales. Estamos aquí para cambiar eso.`}</p>
    <p>{`\\
`}<a parentName="p" {...{
        "href": "https://twitter.com/WPLatinoCaucus"
      }}>{`Follow us on Twitter`}</a>{`!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      